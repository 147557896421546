import React, { Fragment } from 'react';
import CookieConsent from 'react-cookie-consent';
import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
  return (
    <Fragment>
      <footer className='footer'>
        <Container>
          <Row>
            <Col xs='6'>
              <NavLink className='text-muted mr-2' to='/'>
                Notezz
              </NavLink>
            </Col>
            <Col xs='6' className='text-right'>
              <NavLink className='text-muted mr-2' to='/about'>
                About
              </NavLink>
              <NavLink className='text-muted mr-2' to='/terms'>
                Terms
              </NavLink>
              <NavLink className='text-muted mr-2' to='/privacy'>
                Privacy
              </NavLink>
            </Col>
          </Row>
        </Container>
      </footer>
      <CookieConsent buttonStyle={{ 'border-radius': '4px' }}>We use cookies like everyone else.</CookieConsent>
    </Fragment>
  );
};

export default Footer;
