import React, { useContext } from 'react';
import FacebookLogin from 'react-facebook-login';
import AuthContext from '../../context/auth/authContext';

const Facebook = (props) => {
  const authContext = useContext(AuthContext);

  const { isAuthenticated, loginWithFacebook } = authContext;

  const responseFacebook = (response) => {
    loginWithFacebook(response);
  };

  let fbContent;

  if (isAuthenticated) {
    fbContent = null;
  } else {
    fbContent = (
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        autoLoad={false}
        cssClass='btn btn-primary'
        fields='email'
        callback={responseFacebook}
        isMobile={false}
        disableMobileRedirect={true}
      />
    );
  }
  return <div>{fbContent}</div>;
};

export default Facebook;
