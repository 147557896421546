import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import AuthContext from '../../context/auth/authContext';
import Facebook from './Facebook';

const Register = (props) => {
  const authContext = useContext(AuthContext);

  const { register, error, clearErrors, isAuthenticated } = authContext;

  const [user, setuser] = useState({
    username: '',
    password: '',
    password2: '',
  });

  const { username, password, password2 } = user;

  const onChange = (e) => {
    return setuser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (username === '' || password === '') {
      toast('Please enter all fields.');
      clearErrors();
    } else if (password !== password2) {
      toast('Passwords do not match.');
      clearErrors();
    } else {
      register({ username, password });
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/notes');
    }

    if (error) {
      toast(error);
      clearErrors();
    }

    // eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  return (
    <Fragment>
      <Helmet>
        <title>Notezz - Register</title>
        <meta name='description' content='Register to start using Notezz.' />
      </Helmet>
      <Row className='d-flex flex-wrap align-items-center'>
        <Col xs='12' sm='6'>
          <div className='pb-2 mt-4 mb-2 border-bottom'>
            <h1>Register</h1>
          </div>
          <p>Register below and start taking notes in seconds.</p>
          <Form onSubmit={onSubmit}>
            <FormGroup>
              <Label for='username'>Username</Label>
              <Input type='text' id='username' placeholder='Enter username' name='username' value={username} onChange={onChange} required='required' />
            </FormGroup>

            <FormGroup>
              <Label for='password'>Password</Label>
              <Input type='password' id='password' placeholder='Enter password' name='password' value={password} onChange={onChange} required='required' minLength='6' />
            </FormGroup>

            <FormGroup>
              <Label for='password2'>Password confirmation</Label>
              <Input type='password' id='password2' placeholder='Confirm password' name='password2' value={password2} onChange={onChange} required='required' minLength='6' />
            </FormGroup>

            <FormGroup>
              <small id='termsAndConditionsHelpBlock' className='form-text text-muted'>
                By registering, you agree to our <NavLink to='/terms'>Terms and Conditions</NavLink> and <NavLink to='/privacy'>Privacy Policy</NavLink>.
              </small>
            </FormGroup>

            <div className='btn-group mr-2 mb-2' role='group' aria-label='Register with username'>
              <Button type='submit' className='btn btn-secondary'>
                <i className='fa fa-user' aria-hidden='true'></i> Register
              </Button>
            </div>
            <div className='btn-group mr-2 mb-2' role='group' aria-label='Register with Facebook'>
              <Facebook />
            </div>
          </Form>
          <div className='pb-2 mt-4 mb-2'>
            <h4 className='pb-2 mb-2 border-bottom'>Already have an account?</h4>
            <NavLink className='btn btn-secondary' to='/login'>
              <i className='fa fa-sign-in' aria-hidden='true'></i> Login
            </NavLink>
          </div>
        </Col>
        <Col className='d-none d-sm-block' xs='12' sm='6'>
          <Container>
            <Row className='d-flex flex-wrap align-items-center justify-content-center text-center'>
              <Col xs='10'>
                <img src='/register_page_art.svg' className='img-fluid' alt='Take notes online'></img>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Register;
