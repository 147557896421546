import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'reactstrap';

const About = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Notezz - About</title>
        <meta name='description' content='Learn about Notezz and its background.' />
      </Helmet>
      <Row className='d-flex flex-wrap align-items-center'>
        <Col xs='12' sm='4'>
          <img src='/about_page_art.svg' className='img-fluid' alt='Take notes online'></img>
        </Col>
        <Col xs='12' sm='8'>
          <div className='pb-2 mt-4 mb-2 border-bottom'>
            <h1>About Notezz</h1>
          </div>
          <p>
            Notezz is a simple note taking application where you can take notes online without having to worry about installing, customizing, learning, getting-used-to, paying, security, privacy or
            anything else basically.
          </p>

          <p>
            Originally created by Chirstopher Lindblom, Notezz is now run and maintained by Sushil Gupta as a side project. First version of Notezz was started in 2006 and since then more than 50,000
            notes have been created by more than 10,000 users and growing slowly everyday.
          </p>

          <p>Notezz is not a commercial project, and as such, there is no monetization done in Notezz in anyway whatsoever and will likely remain so for the foreseeable future.</p>

          <p>Feel free to send us a quick email at support@notezz.com if you are facing any issues or just want to say hi and we'll be happy to get back to you.</p>

          <p>
            You can also find us on Twitter at <a href='https://twitter.com/Notezzapp' target='_blank' rel='noopener noreferrer'>
              @Notezzapp
            </a>
            .
          </p>
        </Col>
      </Row>
    </Fragment>
  );
};

export default About;
