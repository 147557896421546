import './App.css';

import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import Account from './components/auth/Account';
import Login from './components/auth/Login';
import LoginByLink from './components/auth/LoginByLink';
import Register from './components/auth/Register';
import RequestLoginLink from './components/auth/RequestLoginLink';
import Footer from './components/layouts/Footer';
import Navbar from './components/layouts/Navbar';
import Notes from './components/notes/Notes';
import About from './components/pages/About';
import Home from './components/pages/Home';
import NotFound from './components/pages/NotFound';
import Privacy from './components/pages/Privacy';
import Terms from './components/pages/Terms';
import PrivateRoute from './components/routing/PrivateRoute';
import AuthState from './context/auth/AuthState';
import NoteState from './context/note/NoteState';

const tagManagerArgs = {
  gtmId: 'GTM-M69J28R',
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  return (
    <AuthState>
      <NoteState>
        <Router>
          <Fragment>
            <Navbar />
            <div className='container mb-5'>
              <Switch>
                <Route exact path='/' component={Home}></Route>
                <PrivateRoute exact path='/notes' component={Notes}></PrivateRoute>
                <PrivateRoute exact path='/account' component={Account}></PrivateRoute>
                <Route exact path='/about' component={About}></Route>
                <Route exact path='/register' component={Register}></Route>
                <Route exact path='/login' component={Login}></Route>
                <Route exact path='/request-login-link' component={RequestLoginLink}></Route>
                <Route exact path='/login-by-link' component={LoginByLink}></Route>
                <Route exact path='/privacy' component={Privacy}></Route>
                <Route exact path='/terms' component={Terms}></Route>
                <Route path='' component={NotFound}></Route>
              </Switch>
            </div>
            <Footer />
          </Fragment>
        </Router>
      </NoteState>
    </AuthState>
  );
};

export default App;
