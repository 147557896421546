import 'react-toastify/dist/ReactToastify.css';

import PropTypes from 'prop-types';
import React, { Fragment, useContext, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Collapse, Nav, Navbar as BootNavbar, NavbarToggler, NavItem } from 'reactstrap';

import AuthContext from '../../context/auth/authContext';
import NoteContext from '../../context/note/noteContext';

const Navbar = ({ title, icon }) => {
  const authContext = useContext(AuthContext);
  const noteContext = useContext(NoteContext);

  const { isAuthenticated, token, logout } = authContext;
  const { clearNotes } = noteContext;

  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const onLogout = (e) => {
    e.preventDefault();
    clearNotes();
    logout();
    history.push('/login');
  };

  const authLinks = (
    <Fragment>
      <NavItem>
        <NavLink className='nav-link' to='/account'>
          <i className='fa fa-gear' aria-hidden='true'></i> Account
        </NavLink>
      </NavItem>
      <NavItem>
        <a className='nav-link' onClick={onLogout} href='#!'>
          <i className='fa fa-sign-out' aria-hidden='true'></i> <span>Logout</span>
        </a>
      </NavItem>
    </Fragment>
  );

  const guestLinks = (
    <Fragment>
      <NavItem>
        <NavLink className='nav-link' to='/login'>
          <i className='fa fa-sign-in' aria-hidden='true'></i> Login
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink className='nav-link' to='/register'>
          <i className='fa fa-user' aria-hidden='true'></i> Register
        </NavLink>
      </NavItem>
    </Fragment>
  );

  return (
    <Fragment>
      <ToastContainer position='top-center' newestOnTop={true} autoClose={1000} hideProgressBar={true} pauseOnFocusLoss={false} />
      <div>
        <BootNavbar color='dark' dark expand='md' className='mb-3'>
          <NavLink className='navbar-brand' to={isAuthenticated && token ? '/notes' : '/'}>
            <img src='/logo.webp' alt={title} />
          </NavLink>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className='mr-auto' navbar>
              {isAuthenticated && token ? (
                <NavItem>
                  <NavLink className='nav-link' to='/notes'>
                    <i className='fa fa-book' aria-hidden='true'></i> All notes
                  </NavLink>
                </NavItem>
              ) : (
                ''
              )}
            </Nav>
            <Nav className='ml-auto' navbar>
              {isAuthenticated && token ? authLinks : guestLinks}
            </Nav>
          </Collapse>
        </BootNavbar>
      </div>
    </Fragment>
  );
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

Navbar.defaultProps = {
  title: 'Notezz',
  icon: 'far fa-file',
};

export default Navbar;
