import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'reactstrap';

const Terms = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Notezz - Terms and conditions</title>
        <meta name='description' content='Terms and conditions for using Notezz.' />
      </Helmet>
      <Row className='d-flex flex-wrap align-items-center'>
        <Col xs='12'>
          <div className='pb-2 mt-4 mb-2 border-bottom'>
            <h1>Terms and Conditions</h1>
          </div>
          <p>Please read these Terms and Conditions carefully before using our service.</p>

          <p>By accessing or using the service you agree to be bound by these Terms and Conditions. If you disagree with any part of these Terms and Conditions then you may not access the service.</p>
          <p>You represent that you are over the age of 18. We do not permit those under 18 to use the service.</p>
          <p>Your access to and use of the service is also conditioned on your acceptance of and compliance with our Privacy Policy.</p>

          <h3>Termination</h3>
          <p>
            We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms and Conditions.
          </p>
          <p>Upon termination, your right to use the service will cease immediately.</p>

          <h3>No guarantee</h3>
          <p>
            To the maximum extent permitted by applicable law, in no event shall we or our suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including,
            but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to
            the use of or inability to use the service, third-party software and/or third-party hardware used with the service, or otherwise in connection with any provision of this Terms), even if we
            or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.
          </p>

          <p>The service is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind.</p>

          <h3>Governing Law</h3>
          <p>Your use of Notezz is subject to local, state, national and international laws.</p>

          <h3>Disputes Resolution</h3>
          <p>If you have any concern or dispute about the service, you agree to first try to resolve the dispute informally by contacting us.</p>

          <h3>Changes to These Terms and Conditions</h3>
          <p>
            We reserve the right, at our sole discretion, to modify or replace these Terms and Conditions at any time. By continuing to access or use our service after those revisions become
            effective, you agree to be bound by the revised terms. If you do not agree to the new terms, in whole or in part, please stop using the website and the service.
          </p>

          <h3>Contact us</h3>
          <p>If you have any questions about these Terms and Conditions, you can contact us by email: support@notezz.com</p>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Terms;
