import React, { useContext, useState } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';

import NoteContext from '../../context/note/noteContext';

const NoteFilter = () => {
  const noteContext = useContext(NoteContext);

  const { filterNotes, clearFilter } = noteContext;

  const [search, setSearch] = useState({ keyword: '' });
  const { keyword } = search;

  const onChange = (event) => {
    setSearch({ keyword: event.target.value });

    if (event.target.value) {
      filterNotes(event.target.value);
    } else {
      clearFilter();
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    console.log('submitting', search.keyword);
    if (search.keyword === '') {
      return;
    }
  };

  return (
    <Form onSubmit={onSubmit} autoComplete='off'>
      <FormGroup>
        <Input type='text' name='keyword' value={keyword} placeholder='Search Notes' onChange={onChange} />
      </FormGroup>
    </Form>
  );
};

export default NoteFilter;
