import React, { Fragment, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input } from 'reactstrap';

import NoteContext from '../../context/note/noteContext';

const NoteForm = () => {
  const noteContext = useContext(NoteContext);
  const { addNote, updateNote, deleteNote, current, clearCurrent, error, clearErrors } = noteContext;
  const [note, setNote] = useState({ title: '', body: '', status: 'ACTIVE' });
  const { title, body } = note;

  useEffect(() => {
    if (current !== null) {
      setNote(current);
    }

    if (error !== null) {
      toast(error);
      clearErrors();
    }
  }, [noteContext, current, error, clearErrors]);

  const clearAll = () => {
    clearCurrent();
    setNote({ title: '', body: '', status: 'ACTIVE' });
  };

  const onDelete = (e) => {
    e.preventDefault();
    const deleteConfirmed = window.confirm('Are you sure? This note will be gone for good!');
    if (deleteConfirmed) {
      deleteNote(note.id);
      clearAll();
    }
  };

  const onChange = (event) => {
    setNote({ ...note, [event.target.name]: event.target.value });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (note.title === '' && note.body === '') {
      return;
    }

    if (current === null) {
      if (await addNote(note)) {
        clearAll();
      }
    } else {
      await updateNote(note);
    }
  };

  return (
    <div>
      <Form onSubmit={onSubmit} autoComplete='off'>
        <FormGroup>
          <Input type='text' placeholder='Note title' value={title} name='title' onChange={onChange} id='noteTitle' />
        </FormGroup>
        <FormGroup>
           <Input type='textarea' name='body' id='noteBody' cols='30' rows='10' placeholder='Type your note here' value={body} onChange={onChange} />
          {/*<ReactQuill theme='snow' value={body} id='noteBody' onChange={setNoteBody} rows='50' />*/}
        </FormGroup>
        <div className='mb-2'>
          <Button className='mb-2 mr-2' type='submit' color='primary'>
            {current ? (
              <Fragment>
                <i className='fa fa-floppy-o'></i> Save Note
              </Fragment>
            ) : (
              <Fragment>
                <i className='fa fa-floppy-o'></i> Add Note
              </Fragment>
            )}
          </Button>
          {current && (
            <Fragment>
              <Button className='mb-2 mr-2' color='secondary' onClick={clearAll}>
                <i className='fa fa-times' aria-hidden='true'></i> Close Note
              </Button>
              <Button className='mb-2 mr-2' color='warning' onClick={onDelete}>
                <i className='fas fa-trash'></i> Delete
              </Button>
            </Fragment>
          )}
        </div>
      </Form>
    </div>
  );
};

export default NoteForm;
