import axios from 'axios';
import React, { useReducer } from 'react';

import setAuthToken from '../../utils/setAuthToken';
import {
  ACCOUNT_DELETE_ERROR,
  ACCOUNT_DELETED,
  ACCOUNT_UPDATE_ERROR,
  ACCOUNT_UPDATED,
  AUTH_ERROR,
  CLEAR_ERRORS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SEND_LOGIN_LINK_ERROR,
  SEND_LOGIN_LINK_SUCCESS,
  USER_LOADED,
} from '../types';
import AuthContext from './authContext';
import AuthReducer from './authReducer';

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem('token'),
    refreshToken: localStorage.getItem('refreshToken'),
    isAuthenticated: null,
    loading: true,
    error: null,
    user: null,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const loginWithFacebook = async (facebookData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/facebook`,
        {
          params: { access_token: facebookData.accessToken },
        },
        config
      );
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      setAuthToken(res.data.token);
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response ? err.response.data.message : null,
      });
    }
  };

  const loginByLink = async (oneTimeLoginToken) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login-by-link`, { oneTimeLoginToken }, config);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      setAuthToken(res.data.token);
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response ? err.response.data.message : null,
      });
    }
  };

  const loadUser = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/load`);

      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

  const logout = () => {
    setAuthToken();
    dispatch({ type: LOGOUT, payload: null });
  };

  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  const register = async (formData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/signup`, formData, config);
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
      setAuthToken(res.data.token);
    } catch (err) {
      dispatch({
        type: REGISTER_FAIL,
        payload: err.response ? err.response.data.message : null,
      });
    }
  };

  const login = async (formData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/signin`, formData, config);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      setAuthToken(res.data.token);
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response ? err.response.data.message : 'Could not login!',
      });
    }
  };

  const sendLoginLink = async (formData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/send-login-link`, formData, config);
      dispatch({
        type: SEND_LOGIN_LINK_SUCCESS,
        payload: 'Login link sent by email!',
      });
    } catch (err) {
      dispatch({
        type: SEND_LOGIN_LINK_ERROR,
        payload: err.response ? err.response.data.message : 'Something went wrong!',
      });
    }
  };

  const deleteAccount = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/auth/delete`, config);
      dispatch({
        type: ACCOUNT_DELETED,
        payload: 'All notes and account fully deleted!',
      });
    } catch (err) {
      dispatch({
        type: ACCOUNT_DELETE_ERROR,
        payload: err.response ? err.response.data.message : null,
      });
    }
  };

  const updateAccount = async (formData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/update`, formData, config);
      dispatch({
        type: ACCOUNT_UPDATED,
        payload: 'Account updated!',
      });
    } catch (err) {
      dispatch({
        type: ACCOUNT_UPDATE_ERROR,
        payload: err.response ? err.response.data.message : null,
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        refreshToken: state.refreshToken,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        error: state.error,
        user: state.user,
        register,
        loadUser,
        login,
        logout,
        clearErrors,
        loginWithFacebook,
        updateAccount,
        deleteAccount,
        sendLoginLink,
        loginByLink,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
