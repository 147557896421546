import axios from 'axios';
import React, { useReducer } from 'react';

import { ADD_NOTE, CLEAR_CURRENT, CLEAR_ERRORS, CLEAR_FILTER, CLEAR_NOTES, DELETE_NOTE, FILTER_NOTES, GET_NOTES, NOTE_ERROR, SET_CURRENT, UPDATE_NOTE } from '../types';
import NoteContext from './noteContext';
import NoteReducer from './noteReducer';

const NoteState = (props) => {
  const initialState = {
    notes: null,
    current: null,
    filtered: null,
    search: null,
    error: null,
  };

  const [state, dispatch] = useReducer(NoteReducer, initialState);

  const handleNoteApiError = (error) => {
    const error_message = `Error: ${error?.response?.status}`;

    dispatch({
      type: NOTE_ERROR,
      payload: error_message,
    });
  };

  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  const getNotes = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/notes`, config);
      dispatch({ type: GET_NOTES, payload: res.data });
    } catch (err) {
      handleNoteApiError(err);
    }
  };

  const addNote = async (note) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/notes`, note, config);
      dispatch({ type: ADD_NOTE, payload: res.data });
    } catch (err) {
      handleNoteApiError(err);
      return false;
    }

    return true;
  };

  const deleteNote = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/notes/${id}`);
      dispatch({ type: DELETE_NOTE, payload: id });
    } catch (err) {
      handleNoteApiError(err);
    }
  };

  const updateNote = async (note) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.patch(`${process.env.REACT_APP_API_URL}/notes/${note.id}`, note, config);
      dispatch({ type: UPDATE_NOTE, payload: res.data });
    } catch (err) {
      handleNoteApiError(err);
    }
  };

  const setCurrent = (note) => {
    dispatch({ type: SET_CURRENT, payload: note });
  };

  const clearCurrent = () => {
    dispatch({ type: CLEAR_CURRENT });
  };

  const clearNotes = () => {
    dispatch({ type: CLEAR_NOTES });
  };

  const filterNotes = (text) => {
    dispatch({ type: FILTER_NOTES, payload: text });
  };

  const clearFilter = () => {
    dispatch({ type: CLEAR_FILTER });
  };

  return (
    <NoteContext.Provider
      value={{
        notes: state.notes,
        current: state.current,
        filtered: state.filtered,
        error: state.error,
        addNote,
        deleteNote,
        updateNote,
        setCurrent,
        clearCurrent,
        clearFilter,
        getNotes,
        filterNotes,
        clearNotes,
        clearErrors,
      }}
    >
      {props.children}
    </NoteContext.Provider>
  );
};

export default NoteState;
