import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import AuthContext from '../../context/auth/authContext';
import Facebook from './Facebook';

const Login = (props) => {
  const authContext = useContext(AuthContext);

  const { login, error, token, clearErrors, isAuthenticated } = authContext;

  const [user, setUser] = useState({
    username: '',
    password: '',
  });

  const { username, password } = user;

  const onChange = (event) => {
    return setUser({ ...user, [event.target.name]: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (username === '' || password === '') {
      toast('Please enter all fields.');
      clearErrors();
    } else {
      login({ username, password });
    }
  };

  useEffect(() => {
    if (isAuthenticated === true && token !== null) {
      props.history.push('/notes');
    }

    if (error) {
      toast(error);
      clearErrors();
    }

    // eslint-disable-next-line
  }, [error, isAuthenticated, token]);

  return (
    <Fragment>
      <Helmet>
        <title>Notezz - Login</title>
        <meta name='description' content='Log in to Notezz.' />
      </Helmet>
      <Row className='d-flex flex-wrap align-items-center'>
        <Col xs='12' sm='6'>
          <div className='pb-2 mt-4 mb-2 border-bottom'>
            <h1>Login</h1>
          </div>
          <p>Login below to see all your old notes.</p>
          <Form onSubmit={onSubmit}>
            <FormGroup>
              <Label for='username'>Username</Label>
              <Input type='text' id='username' placeholder='Enter username' name='username' value={username} onChange={onChange} required />
            </FormGroup>
            <FormGroup>
              <Label for='password'>Password</Label>
              <Input id='password' type='password' placeholder='Enter password' name='password' value={password} onChange={onChange} required />
            </FormGroup>

            <div className='btn-group mr-2 mb-2' role='group' aria-label='Login with username'>
              <Button type='submit' className='btn btn-secondary'>
                <i className='fa fa-sign-in' aria-hidden='true'></i> Login
              </Button>
            </div>
            <div className='btn-group mr-2 mb-2' role='group' aria-label='Login with Facebook'>
              <Facebook />
            </div>
            <div className='btn-group mr-2 mb-2' role='group' aria-label='Login with Facebook'>
              <NavLink to='/request-login-link'>Forgot password?</NavLink>
            </div>
          </Form>
          <div className='pb-2 mt-4 mb-2'>
            <h4 className='pb-2 mb-2 border-bottom'>Don't have an account?</h4>
            <NavLink className='btn btn-secondary' to='/register'>
              <i className='fa fa-user' aria-hidden='true'></i> Register
            </NavLink>
          </div>
        </Col>
        <Col className='d-none d-sm-block' xs='12' sm='6'>
          <Container>
            <Row className='d-flex flex-wrap align-items-center justify-content-center text-center'>
              <Col xs='10'>
                <img src='/login_page_art.svg' className='img-fluid' alt='Take notes online'></img>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Login;
