import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ReactTimeAgo from 'react-time-ago';
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Row } from 'reactstrap';

import NoteContext from '../../context/note/noteContext';

const NoteItem = ({ note }) => {
  const noteContext = useContext(NoteContext);
  const { deleteNote, setCurrent, clearCurrent, current } = noteContext;
  const { title, body, updated } = note;

  let isBeingEdited = false;
  if (note !== null && current !== null) {
    isBeingEdited = current.id === note.id;
  }

  const onDelete = (e) => {
    e.preventDefault();
    const deleteConfirmed = window.confirm('Are you sure? This note will be gone for good!');
    if (deleteConfirmed) {
      deleteNote(note.id);
      clearCurrent();
    }
  };

  return (
    <Row>
      <Col sm='12' className='mb-2'>
        <Card>
          <CardBody>
            {title && <CardTitle>{title}</CardTitle>}
            <CardSubtitle>
              <small>
                <ReactTimeAgo date={new Date(updated)} />
              </small>
            </CardSubtitle>
            <CardText dangerouslySetInnerHTML={{ __html: body }}></CardText>
            <Button
              className='btn-sm mb-2 mr-2'
              disabled={isBeingEdited}
              onClick={() => {
                setCurrent(note);
                window.scrollTo(0, 0);
              }}
            >
              <i className='fa fa-pencil-square-o' aria-hidden='true'></i> <div className='d-none d-sm-inline'>Edit</div>
            </Button>
            <Button className='btn-sm mb-2 mr-2 float-right' color='warning' onClick={onDelete} disabled={isBeingEdited}>
              <i className='fas fa-trash'></i> <div className='d-none d-sm-inline'>Delete</div>
            </Button>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

NoteItem.propTypes = {
  note: PropTypes.object.isRequired,
};

export default NoteItem;
