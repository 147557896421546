import React, { Fragment, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { Col, Container, Row, Spinner } from 'reactstrap';

import AuthContext from '../../context/auth/authContext';

const queryString = require('query-string');

const LoginByLink = (props) => {
  const authContext = useContext(AuthContext);

  const { loginByLink, error, clearErrors, isAuthenticated } = authContext;

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/notes');
    }

    if (error) {
      toast(error);
      clearErrors();
      props.history.push('/login');
    }

    // eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  const queryParams = queryString.parse(props.location.search);

  useEffect(() => {
    if (!queryParams.token) {
      toast('Not one time login token provided!');
      props.history.push('/login');
    } else {
      loginByLink(queryParams.token);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Notezz - Login by link</title>
        <meta name='description' content='Log in to Notezz using one time link.' />
      </Helmet>
      <Row className='d-flex flex-wrap align-items-center'>
        <Col xs='12' className='text-center'>
          <div className='text-center'>
            <Spinner />
          </div>
          <div>Logging you in...</div>
        </Col>
      </Row>
      <Row>
        <Col className='d-none d-sm-block' xs='12'>
          <Container>
            <Row className='d-flex flex-wrap align-items-center justify-content-center text-center'>
              <Col xs='4' className='my-5'>
                <img src='/one_time_login_art.svg' className='img-fluid' alt='Forgot password'></img>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Fragment>
  );
};

export default LoginByLink;
