import {
  ACCOUNT_DELETE_ERROR,
  ACCOUNT_DELETED,
  ACCOUNT_UPDATE_ERROR,
  ACCOUNT_UPDATED,
  AUTH_ERROR,
  CLEAR_ERRORS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SEND_LOGIN_LINK_ERROR,
  SEND_LOGIN_LINK_SUCCESS,
  USER_LOADED,
} from '../types';

export default (state, action) => {
  const firstPayloadValue = Array.isArray(action.payload) ? action.payload[0] : action.payload;
  switch (action.type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
      };
    case REGISTER_SUCCESS:
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loading: false,
        error: 'Welcome to Notezz!',
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loading: false,
        error: 'Welcome back!',
      };
    case SEND_LOGIN_LINK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SEND_LOGIN_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ACCOUNT_UPDATED:
      return {
        ...state,
        loading: false,
        error: firstPayloadValue,
      };
    case ACCOUNT_DELETED:
      return {
        ...state,
        loading: false,
        error: firstPayloadValue,
      };
    case ACCOUNT_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: firstPayloadValue,
      };
    case ACCOUNT_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: firstPayloadValue,
      };
    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        error: firstPayloadValue,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
