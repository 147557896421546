import React, {Fragment, useContext, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Col, Row, Spinner} from 'reactstrap';

import AuthContext from '../../context/auth/authContext';
import NoteContext from '../../context/note/noteContext';
import NoteFilter from './NoteFilter';
import NoteForm from './NoteForm';
import NoteItem from './NoteItem';

const Notes = () => {
    const authContext = useContext(AuthContext);
    const noteContext = useContext(NoteContext);
    const {isAuthenticated, token} = authContext;
    const authLoading = authContext.loading;

    const {notes, filtered, getNotes, loading, error} = noteContext;

    const sortByUpdated = (note1, note2) => {
        return new Date(note2.updated) - new Date(note1.updated);
    };

    const removeHTMLTags = (str) => {
        return str.replace(/<[^>]*>?/gm, '');
    }

    useEffect(() => {
        if (!isAuthenticated || !token || error !== null) {
            authContext.loadUser();
        } else {
            getNotes();
        }
        // eslint-disable-next-line
    }, [authContext, error]);

    const notesExist = notes !== null && !loading;
    const filterExists = filtered !== null && !loading;

    return (
        <Fragment>
            <Helmet>
                <title>Notezz - All notes</title>
                <meta name='description' content='See all your notes.'/>
            </Helmet>
            <Row>
                <Col sm='12'>
                    {isAuthenticated && !authLoading ? (
                        <Fragment>
                            <Row className='mt-2'>
                                <Col sm='12'>
                                    <NoteForm/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm='12'>{notes !== null && !loading && notes.length !== 0 ?
                                    <NoteFilter/> : ''}</Col>
                            </Row>
                            <Row>
                                <Col sm='12'>
                                    {notesExist ? (
                                        <div>
                                            {filterExists ? (
                                                <div>
                                                    {filtered.sort(sortByUpdated).map((note) => (
                                                        <NoteItem key={note.id} note={note}/>
                                                    ))}
                                                    {filtered.length === 0 ? (
                                                        <Row
                                                            className='d-flex flex-wrap align-items-center justify-content-center text-center'>
                                                            <Col xs='6' sm='6'>
                                                                <img src='/empty_art.svg' className='img-fluid'
                                                                     alt='No notes found!'></img>
                                                            </Col>
                                                        </Row>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            ) : (
                                                <div>
                                                    {notes.sort(sortByUpdated).map((note) => (
                                                        <NoteItem key={note.id} note={
                                                            {
                                                                "body": removeHTMLTags(note.body),
                                                                ...note
                                                            }
                                                        }/>
                                                    ))}
                                                    {notes.length === 0 ? (
                                                        <Row
                                                            className='d-flex flex-wrap align-items-center justify-content-center text-center'>
                                                            <Col xs='6' sm='6'>
                                                                <img src='/welcome_art.svg' className='img-fluid'
                                                                     alt='Welcome to Notezz!'></img>
                                                            </Col>
                                                        </Row>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className='text-center'>
                                            <Spinner/>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Fragment>
                    ) : (
                        <div className='text-center'>
                            <Spinner/>
                        </div>
                    )}
                </Col>
            </Row>
        </Fragment>
    );
};

export default Notes;
