import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'reactstrap';

const Privacy = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Notezz - Privacy policy</title>
        <meta name='description' content='Privacy policy of Notezz.' />
      </Helmet>
      <Row className='d-flex flex-wrap align-items-center'>
        <Col xs='12'>
          <div className='pb-2 mt-4 mb-2 border-bottom'>
            <h1>Privacy Policy</h1>
          </div>
          <div>
            <p>We use your personal data to provide and improve the service. By using the service, you agree to the collection and use of information in accordance with this Privacy Policy.</p>

            <h3>Personal data</h3>
            <p>
              While using our service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information
              may include, but is not limited to:
            </p>
            <ul>
              <li>Email address</li> <li>Usage data</li>
            </ul>

            <h3>Usage data</h3>
            <p>Usage data is collected automatically when using the service.</p>

            <h3>Tracking technologies and cookies</h3>
            <p>We use Cookies and similar tracking technologies to track the activity on our service and store certain information.</p>

            <h3>Use of your personal data</h3>
            <p>We may use personal data for the following purposes:</p>
            <ul>
              <li>
                <strong>To provide and maintain our service</strong>, including to monitor the usage of our service.
              </li>
              <li>
                <strong>To manage your requests:</strong> To attend and manage your requests to us.
              </li>
            </ul>

            <p>We may share and/or transfer your personal information in the following situations:</p>
            <ul>
              <li>
                <strong>With service providers:</strong> We may share your personal information with service providers to monitor and analyze the use of our service, to contact you.
              </li>
              <li>
                <strong>For business transfers:</strong> We may share or transfer your personal information in connection with, or during negotiations of, any merger, sale of company assets,
                financing, or acquisition of all or a portion of our business to another company.
              </li>
              <li>
                <strong>For legal requirements:</strong> Under certain circumstances, we may be required to disclose your personal data if required to do so by law or in response to valid requests by
                public authorities (e.g. a court or a government agency).
              </li>
            </ul>

            <h3>Security of your personal data</h3>
            <p>
              The security of your personal data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.
            </p>

            <h3>Deletion of your personal data</h3>
            <p>
              All your notes can be deleted once you login to the account. If you wish to have your account deleted as well, please email info@notezz.com with your account details and it will be deleted once it is verified that you are the account owner.
            </p>

            <h3>Changes to this Privacy Policy</h3>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

            <h3>Contact us</h3>
            <p>If you have any questions about this Privacy Policy, you can contact us by email: support@notezz.com</p>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Privacy;
