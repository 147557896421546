import { ADD_NOTE, DELETE_NOTE, UPDATE_NOTE, SET_CURRENT, CLEAR_CURRENT, FILTER_NOTES, CLEAR_FILTER, NOTE_ERROR, GET_NOTES, CLEAR_NOTES, CLEAR_ERRORS } from '../types';

export default (state, action) => {
  let filteredNotes = {};

  switch (action.type) {
    case ADD_NOTE:
      if (state.filtered) {
        filteredNotes = {
          filtered: [action.payload, ...state.filtered].filter((note) => {
            const regex = new RegExp(`${state.search}`, 'gi');
            return note.title.match(regex) || note.body.match(regex);
          }),
        };
      }

      return {
        ...state,
        ...filteredNotes,
        notes: [action.payload, ...state.notes],
        loading: false,
      };
    case GET_NOTES:
      return {
        ...state,
        notes: action.payload,
        loading: false,
      };
    case UPDATE_NOTE:
      if (state.filtered) {
        filteredNotes = {
          filtered: state.filtered
            .map((note) => {
              if (note.id === action.payload.id) {
                return action.payload;
              } else {
                return note;
              }
            })
            .filter((note) => {
              const regex = new RegExp(`${state.search}`, 'gi');
              return note.title.match(regex) || note.body.match(regex);
            }),
        };
      }

      return {
        ...state,
        ...filteredNotes,
        current: action.payload,
        notes: state.notes.map((note) => {
          if (note.id === action.payload.id) {
            return action.payload;
          } else {
            return note;
          }
        }),
        loading: false,
        error: 'Note saved!',
      };
    case DELETE_NOTE:
      if (state.filtered) {
        filteredNotes = {
          filtered: state.filtered.filter((note) => note.id !== action.payload),
        };
      }

      return {
        ...state,
        ...filteredNotes,
        notes: state.notes.filter((note) => note.id !== action.payload),
        loading: false,
      };
    case NOTE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    case FILTER_NOTES:
      return {
        ...state,
        search: action.payload,
        filtered: state.notes.filter((note) => {
          const regex = new RegExp(`${action.payload}`, 'gi');
          return note.title.match(regex) || note.body.match(regex);
        }),
        loading: false,
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null,
        search: null,
      };
    case CLEAR_NOTES:
      return {
        ...state,
        notes: null,
        filtered: null,
        error: null,
      };
    default:
      return state;
  }
};
