import 'bootstrap/dist/css/bootstrap.min.css';

import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import refreshAuthLogic from './utils/retryRequest';
import setAuthToken from './utils/setAuthToken';

JavascriptTimeAgo.addLocale(en);

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

createAuthRefreshInterceptor(axios, refreshAuthLogic);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
