import React, { Fragment, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardText, CardTitle, Col, Container, Row } from 'reactstrap';

import AuthContext from '../../context/auth/authContext';

const faqs = [
  {
    title: 'Can I signup and store notes anonymously?',
    content: `Yes.`,
  },
  {
    title: 'Are my notes encrypted?',
    content: `Yes, all notes are encrypted before being saved in database.`,
  },
  {
    title: 'Can I delete my account after signing up?',
    content: `Yes, and it'll be permanently deleted from our system.`,
  },
];

const Home = (props) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated } = authContext;

  useEffect(() => {
    if (isAuthenticated || localStorage.token) {
      props.history.push('/notes');
    }
  }, [authContext, isAuthenticated, props.history]);

  return (
    <Fragment>
      <Helmet>
        <title>Notezz - Simple note taking application</title>
        <meta name='description' content='Take notes online without worrying about installing, customizing, learning, getting-used-to, paying, security, privacy or anything else basically.' />
      </Helmet>

      <Row className='d-flex flex-wrap align-items-center mb-5'>
        <Col xs='12' sm='6' className='mb-5 text-center  text-md-left'>
          <h1>Simple & free note taking tool</h1>
          <p className='mb-5'>Take notes online without worrying about installing, customizing, learning, getting-used-to, paying, security, privacy or anything else basically.</p>
          <div className='text-center text-md-left'>
            <NavLink className='btn btn-secondary mr-2' to='/login'>
              <i className='fa fa-sign-in' aria-hidden='true'></i> Login
            </NavLink>
            <NavLink className='btn btn-primary mr-2' to='/register'>
              <i className='fa fa-user' aria-hidden='true'></i> Register
            </NavLink>
          </div>
        </Col>
        <Col xs='12' sm='6'>
          <img src='/landing_page_art.svg' className='img-fluid' alt='About taking notes online'></img>
        </Col>
      </Row>

      <Row className='d-flex flex-wrap align-items-center mb-5'>
        <Col xs='12' sm='12'>
          <h2 className='text-center'>Features</h2>
          <p className='text-center'>Simplicity is what stands out Notezz! Find the features we are proud of below.</p>
        </Col>
      </Row>

      <Row className='d-flex flex-wrap align-items-center mb-5 features'>
        <Col xs='12'>
          <Container>
            <Row>
              <Col xs='6'>
                <Container>
                  <Row className='d-flex flex-wrap align-items-center mb-5'>
                    <Col xs='12' sm='3' className='text-center'>
                      <i className='fas fa-vial'></i>
                    </Col>
                    <Col xs='12' sm='9'>
                      <h4>Simple to use</h4>
                      <p>Focus on taking and reading notes without getting caught up on bells and whistles, because simplicity matters</p>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col xs='6'>
                <Container>
                  <Row className='d-flex flex-wrap align-items-center mb-5'>
                    <Col xs='12' sm='3' className='text-center'>
                      <i className='fas fa-running'></i>
                    </Col>
                    <Col xs='12' sm='9'>
                      <h4>Get started fast</h4>
                      <p>Start taking notes in one click anywhere on your Mobile or on Desktop, no need to install apps or verify email account</p>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col xs='6'>
                <Container>
                  <Row className='d-flex flex-wrap align-items-center mb-5'>
                    <Col xs='12' sm='3' className='text-center'>
                      <i className='fa fa-user-secret' aria-hidden='true'></i>
                    </Col>
                    <Col xs='12' sm='9'>
                      <h4>Anonymous</h4>
                      <p>Your account and notes remains anonymous by default - no need to provide email or other personal information to take notes</p>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col xs='6'>
                <Container>
                  <Row className='d-flex flex-wrap align-items-center mb-5'>
                    <Col xs='12' sm='3' className='text-center'>
                      <i className='fas fa-piggy-bank'></i>
                    </Col>
                    <Col xs='12' sm='9'>
                      <h4>Free of cost</h4>
                      <p>Notezz is not a commercial platform, so doesn't serve advertisements or has paid plans - it is free and will always remain so</p>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <Row className='d-flex flex-wrap align-items-center mb-5'>
        <Col xs='12' sm='12'>
          <h2 className='text-center'>Frequently Asked Questions</h2>
          <p className='text-center'>Have concerns? Please read below or let us know at support@notezz.com.</p>
        </Col>
      </Row>

      <Row className='d-flex flex-wrap align-items-center mb-5'>
        <Col xs='12' sm='6' className='d-none d-sm-block'>
          <img src='/faq_section_art.svg' className='img-fluid' alt='Frequently asked questions'></img>
        </Col>
        <Col xs='12' sm='6'>
          {faqs.map((faq, index) => {
            return (
              <Card key={`faq-${index}`} className='mb-2'>
                <CardBody>
                  <CardTitle>{faq.title}</CardTitle>
                  <CardText>{faq.content}</CardText>
                </CardBody>
              </Card>
            );
          })}
        </Col>
      </Row>
    </Fragment>
  );
};

export default Home;
