import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import AuthContext from '../../context/auth/authContext';
import NoteContext from '../../context/note/noteContext';

const Account = (props) => {
  const authContext = useContext(AuthContext);
  const noteContext = useContext(NoteContext);

  const { clearNotes } = noteContext;
  const { user, updateAccount, deleteAccount, error, clearErrors, isAuthenticated, logout } = authContext;

  const [account, setAccount] = useState({
    email: '',
    username: '',
    password: '',
    password_confirmation: '',
  });

  const { email, username, password, password_confirmation } = account;

  useEffect(() => {
    if (user !== null && error === null) {
      setAccount({
        email: email.length ? email : user.email,
        username: username.length ? username : user.username,
        password: '',
        password_confirmation: '',
      });
    }

    if (!isAuthenticated) {
      authContext.loadUser();
    }

    if (error) {
      toast(error);
      clearErrors();
    }

    // eslint-disable-next-line
  }, [user, error, isAuthenticated]);

  const onChange = (event) => {
    return setAccount({ ...account, [event.target.name]: event.target.value });
  };

  const onAccountDelete = async (e) => {
    e.preventDefault();
    const deleteConfirmed = window.confirm('Are you sure? This is irreversible!');
    if (deleteConfirmed) {
      await deleteAccount();
      logout();
      clearNotes();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (username === '') {
      toast('Username is mandatory, email is optional.');
      clearErrors();
    } else if (password !== '' && password !== password_confirmation) {
      toast('Password confirmation does not match!');
      clearErrors();
    } else {
      updateAccount({
        username,
        email,
        ...(password.length && { password }),
      });
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Notezz - Account</title>
        <meta name='description' content='Log in to Notezz.' />
      </Helmet>
      <Row className='d-flex flex-wrap align-items-center'>
        <Col xs='12' sm='6'>
          <div className='pb-2 mt-4 mb-2 border-bottom'>
            <h1>Update account</h1>
          </div>
          <p>Update your account settings below.</p>
          <Form onSubmit={onSubmit} autoComplete='off'>
            <FormGroup>
              <Label for='email'>Email</Label>
              <Input id='email' type='email' placeholder='Enter your email' name='email' value={email} onChange={onChange} />
              <small id='emailHelpBlock' className='form-text text-muted'>
                We'll never share your email with anyone else. It will only be used for sending you login link if you ever forget your password.
              </small>
            </FormGroup>
            <FormGroup>
              <Label for='username'>Username</Label>
              <Input id='username' type='text' placeholder='Enter new username' name='username' value={username} onChange={onChange} required />
            </FormGroup>
            <FormGroup>
              <Label for='password'>Password</Label>
              <Input id='password' type='password' name='password' placeholder='Enter new password' value={password} onChange={onChange} />
            </FormGroup>
            <FormGroup>
              <Label for='password_confirmation'>Password confirmation</Label>
              <Input id='password_confirmation' type='password' name='password_confirmation' placeholder='Confirm new password' value={password_confirmation} onChange={onChange} />
            </FormGroup>
            <Button type='submit' className='btn btn-secondary my-2 mr-2'>
              <i className='fa fa-floppy-o'></i> Update Account
            </Button>
            <Button color='danger' onClick={onAccountDelete}>
              <i className='fas fa-trash'></i> Delete Account & All Notes Forever
            </Button>
          </Form>
        </Col>
        <Col className='d-none d-sm-block'>
          <img src='/account_page_art.svg' alt='Manage your Notezz account' className='img-fluid'></img>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Account;
