import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import AuthContext from '../../context/auth/authContext';

const RequestLoginLink = (props) => {
  const authContext = useContext(AuthContext);

  const { sendLoginLink, error, clearErrors } = authContext;

  const [user, setUser] = useState({
    email: '',
  });

  const { email } = user;

  const onChange = (event) => {
    return setUser({ ...user, [event.target.name]: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (email === '') {
      toast('Please enter email.');
      clearErrors();
    } else {
      sendLoginLink({ email });
    }
  };

  useEffect(() => {
    if (error) {
      toast(error);
      clearErrors();

      if (error === 'Login link sent by email!') {
        setUser({ email: '' });
      }
    }

    // eslint-disable-next-line
  }, [error]);

  return (
    <Fragment>
      <Helmet>
        <title>Notezz - Request login link</title>
        <meta name='description' content='Forgot password? Request link to login.' />
      </Helmet>
      <Row className='d-flex flex-wrap align-items-center'>
        <Col xs='12' sm='6'>
          <div className='pb-2 mt-4 mb-2 border-bottom'>
            <h1>Request login link</h1>
          </div>
          <p>If you have saved email to your account before, you can request an unique one time login link through this form to access your account in case if you have forgotten your password.</p>
          <Form onSubmit={onSubmit}>
            <FormGroup>
              <Label for='email'>Email</Label>
              <Input type='email' id='email' placeholder='Enter email' name='email' value={email} onChange={onChange} />
            </FormGroup>
            <div className='btn-group mr-2 mb-2' role='group' aria-label='Send login link'>
              <Button type='submit' className='btn btn-secondary'>
                <i className='fa fa-link' aria-hidden='true'></i> Send login link
              </Button>
            </div>
            <div className='btn-group mr-2 mb-2' role='group' aria-label='Back to login'>
              <NavLink to='/login'>Back to login</NavLink>
            </div>
          </Form>
        </Col>
        <Col className='d-none d-sm-block' xs='12' sm='6'>
          <Container>
            <Row className='d-flex flex-wrap align-items-center justify-content-center text-center'>
              <Col xs='10'>
                <img src='/request_login_link_art.svg' className='img-fluid' alt='Forgot password'></img>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Fragment>
  );
};

export default RequestLoginLink;
