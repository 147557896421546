import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'reactstrap';

const NotFound = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Notezz - 404</title>
        <meta name='description' content='Page not found.' />
      </Helmet>
      <Row className='d-flex flex-wrap align-items-center justify-content-center text-center'>
        <Col xs='12' sm='6'>
          <img src='/not_found_art.svg' className='img-fluid' alt='Page not found'></img>
        </Col>
      </Row>
    </Fragment>
  );
};

export default NotFound;
