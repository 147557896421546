import axios from 'axios';

import setAuthToken from './setAuthToken';

const refreshAuthLogic = async (failedRequest) => {
  const refreshToken = localStorage.getItem('refreshToken');
  const token = localStorage.getItem('token');
  if (!refreshToken || !token) {
    return;
  }

  setAuthToken(refreshToken);

  await axios.get(`${process.env.REACT_APP_API_URL}/auth/refresh`).then((tokenRefreshResponse) => {
    const newAccessToken = tokenRefreshResponse.data.token;
    localStorage.setItem('token', tokenRefreshResponse.data.token);
    failedRequest.response.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
    setAuthToken(newAccessToken);
    return Promise.resolve();
  });
};

export default refreshAuthLogic;
